import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, RouterModule, Routes } from "@angular/router";
import { NgxMapLibreGLModule } from "@maplibre/ngx-maplibre-gl";
import { NgSelectModule } from "@ng-select/ng-select";
import { CountUpModule } from "ngx-countup";
import { MarkdownModule } from "ngx-markdown";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { AppComponent } from "./app/app.component";
import { canLoadNavigation } from "./app/helpers/can-load-navigation";
import { authInterceptor } from "./app/interceptor/auth/auth.interceptor";
import { GlobalErrorHandler } from "./app/interceptor/global-error-handler";
import { versionInterceptor } from "./app/interceptor/version.interceptor";
import { Route } from "./app/models/routes";
import { NavbarService } from "./app/services/navbar.service";
import { environment } from "./environments/environment";

const routes: Routes = [
	{
		path: `${Route.Login}/:token/:userId`,
		loadComponent: () =>
			import("./app/components/reset-password/reset-password.component").then((m) => m.ResetPasswordComponent),
	},
	{
		path: Route.ForgotPassword,
		loadComponent: () =>
			import("./app/components/forgot-password/forgot-password.component").then((m) => m.ForgotPasswordComponent),
	},
	{ path: "", loadComponent: () => import("./app/components/login/login.component").then((m) => m.LoginComponent) },
	{
		path: Route.Register,
		loadComponent: () => import("./app/components/register/register.component").then((m) => m.RegisterComponent),
	},
	{
		path: Route.Changelog,
		loadComponent: () => import("./app/components/changelog/changelog.component").then((m) => m.ChangelogComponent),
	},
	{
		path: Route.Logout,
		loadComponent: () => import("./app/components/logout/logout.component").then((m) => m.LogoutComponent),
	},
	{
		path: Route.Authorizations,
		loadComponent: () =>
			import("./app/components/authorization-view/authorization-view.component").then(
				(m) => m.AuthorizationViewComponent,
			),
	},
	{
		path: `:declarationId/${Route.Agreement}`,
		loadComponent: () => import("./app/components/agreements/agreements.component").then((m) => m.AgreementsComponent),
	},
	{
		path: "404",
		loadComponent: () => import("./app/components/not-found/not-found.component").then((m) => m.NotFoundComponent),
	},
	{
		path: ":id",
		loadComponent: () => import("./app/components/navigation/navigation.component").then((m) => m.NavigationComponent),
		canActivate: [canLoadNavigation],
		runGuardsAndResolvers: "always",
		children: [
			// all
			{
				path: `:type/${Route.Stats}`,
				loadComponent: () =>
					import("./app/components/navigation/my-declarations/stats/stats.component").then((m) => m.StatsComponent),
			},
			// representative
			{
				path: Route.MyDeclaration,
				loadComponent: () =>
					import("./app/components/navigation/my-declarations/my-declarations.component").then(
						(m) => m.MyDeclarationsComponent,
					),
			},
			{
				path: Route.MyProfile,
				loadComponent: () =>
					import("./app/components/navigation/my-profile/my-profile.component").then((m) => m.MyProfileComponent),
			},
			{
				path: Route.BuyToken,
				loadComponent: () =>
					import("./app/components/navigation/buy-token/buy-token.component").then((m) => m.BuyTokenComponent),
			},
			{
				path: Route.RepresentativeSettings,
				loadComponent: () =>
					import("./app/components/navigation/representative-settings/representative-settings.component").then(
						(m) => m.RepresentativeSettingsComponent,
					),
			},
			{
				path: "404",
				loadComponent: () => import("./app/components/not-found/not-found.component").then((m) => m.NotFoundComponent),
			},
			// declaration
			{
				path: "",
				loadComponent: () =>
					import("./app/components/navigation/stepper/stepper.component").then((m) => m.StepperComponent),
				children: [
					{
						path: Route.Addresses,
						loadComponent: () =>
							import("./app/components/navigation/stepper/siren-form/siren-form.component").then(
								(m) => m.SirenFormComponent,
							),
					},
					{
						path: Route.ConsoList,
						loadComponent: () =>
							import(
								"./app/components/navigation/stepper/functional-entity-stepper/declaration-conso/conso-list/conso-list.component"
							).then((m) => m.ConsoListComponent),
					},
					{
						path: Route.Reference,
						loadComponent: () =>
							import(
								"./app/components/navigation/stepper/reference-year-summary/reference-year-summary.component"
							).then((m) => m.ReferenceYearSummaryComponent),
					},
					{
						path: Route.Csv,
						loadComponent: () =>
							import("./app/components/navigation/stepper/csv/csv.component").then((m) => m.CsvComponent),
					},
					// entity
					{
						path: "",
						loadComponent: () =>
							import(
								"./app/components/navigation/stepper/functional-entity-stepper/functional-entity-stepper.component"
							).then((m) => m.FunctionalEntityStepperComponent),
						children: [
							{
								path: Route.Qualification,
								loadComponent: () =>
									import(
										"./app/components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form-map.component"
									).then((m) => m.QualificationFormMapComponent),
							},
							{
								path: Route.AssetDeclaration,
								loadComponent: () =>
									import(
										"./app/components/navigation/stepper/functional-entity-stepper/asset-declaration/asset-declaration.component"
									).then((m) => m.AssetDeclarationComponent),
							},
							{
								path: Route.ConsoTable,
								loadComponent: () =>
									import(
										"./app/components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table.component"
									).then((m) => m.ConsumptionTableComponent),
							},
							{
								path: Route.ReferenceEntity,
								loadComponent: () =>
									import(
										"./app/components/navigation/stepper/functional-entity-stepper/year-reference/year-reference.component"
									).then((m) => m.YearReferenceComponent),
							},
						],
					},
				],
			},
		],
	},
	{
		path: "**",
		loadComponent: () => import("./app/components/not-found/not-found.component").then((m) => m.NotFoundComponent),
	},
];

registerLocaleData(localeFr);

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			ReactiveFormsModule,
			FormsModule,
			MatStepperModule,
			MatInputModule,
			MatFormFieldModule,
			MatButtonModule,
			MatRadioModule,
			MatCheckboxModule,
			MatExpansionModule,
			MatProgressBarModule,
			MatListModule,
			MatAutocompleteModule,
			MatProgressSpinnerModule,
			MatDialogModule,
			MatTabsModule,
			MatSelectModule,
			MatDatepickerModule,
			MatNativeDateModule,
			MatTableModule,
			MatSnackBarModule,
			MatSidenavModule,
			MatStepperModule,
			MatInputModule,
			MatFormFieldModule,
			MatButtonModule,
			MatRadioModule,
			MatCheckboxModule,
			MatExpansionModule,
			MatProgressBarModule,
			MatListModule,
			MatAutocompleteModule,
			MatProgressSpinnerModule,
			MatDialogModule,
			MatTabsModule,
			MatSelectModule,
			MatDatepickerModule,
			MatNativeDateModule,
			MatTableModule,
			MatSnackBarModule,
			MatMenuModule,
			MatBadgeModule,
			NgxMapLibreGLModule,
			RouterModule,
			MatProgressSpinnerModule,
			MatIconModule,
			MatCardModule,
			NgSelectModule,
			MatTableModule,
			MatTooltipModule,
			NgScrollbarModule,
			MatSlideToggleModule,
			MatSliderModule,
			TourMatMenuModule,
			MarkdownModule.forRoot(),
			CountUpModule,
			RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
		),
		{
			// processes all errors
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		NavbarService,
		provideHttpClient(withInterceptors([authInterceptor, versionInterceptor])),
		provideAnimations(),
		provideRouter(routes),
	],
}).catch((err) => console.error(err));
