import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { VersionService } from "../services/version.service";

export function versionInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	const versionService = inject(VersionService);
	return next(req).pipe(
		tap((response) => {
			if (
				req.url.includes(environment.baseApiUrl) &&
				response instanceof HttpResponse &&
				response.headers.get("Version")
			) {
				versionService.setVersion(response.headers.get("Version"));
			}
		}),
	);
}
