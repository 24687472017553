import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { TourAnchorMatMenuDirective } from "ngx-ui-tour-md-menu";
import { LOGO_SMALL_URL } from "../../helpers/consts";
import { DeclarationFunctionalService } from "../../services/declaration-functional.service";
import { DeclarationStateService } from "../../services/declaration-state.service";
import { DeclarationService } from "../../services/declaration.service";
import { InitialLoadingService } from "../../services/initial-loading.service";

@Component({
	selector: "app-loading",
	templateUrl: "./loading.component.html",
	styleUrls: ["./loading.component.scss"],
	standalone: true,
	imports: [NgIf, MatProgressSpinner, TourAnchorMatMenuDirective, MatIcon],
})
export class LoadingComponent {
	firstLoad = true;
	stopping = false;
	loading = false;
	savingFunctionalEntity = false;
	errorFunctionalEntity = false;
	savingDeclarationEntityFromState = false;
	savingDeclarationEntityFromService = false;
	LOGO_SMALL_URL = LOGO_SMALL_URL;

	constructor(
		functionalEntityService: DeclarationFunctionalService,
		declarationStateService: DeclarationStateService,
		declarationEntityService: DeclarationService,
	) {
		InitialLoadingService.subject.subscribe((value) => {
			if (!value) {
				this.stopping = true;
				setTimeout(() => {
					this.firstLoad = false;
				}, 300);
			}
		});

		InitialLoadingService.countSubject.subscribe((value) => {
			this.loading = value !== 0;
		});

		functionalEntityService.saving$.subscribe((saving) => {
			this.savingFunctionalEntity = saving;
		});

		declarationStateService.saving$.subscribe((saving) => (this.savingDeclarationEntityFromState = saving));
		declarationStateService.errored$.subscribe((error) => (this.errorFunctionalEntity = error));
		declarationEntityService.saving$.subscribe((saving) => (this.savingDeclarationEntityFromService = saving));
	}
}
