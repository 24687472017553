import { ErrorHandler, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomError } from "../models/CustomError";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	private canDisplayError = true;
	constructor(private snackBar: MatSnackBar) {}

	handleError(error: unknown) {
		if (error !== undefined && this.canDisplayError) {
			this.canDisplayError = false;
			const message = error instanceof CustomError ? error.message : "Une erreur est survenue.";
			const duration = error instanceof CustomError ? undefined : 3000;
			const throwError = !(error instanceof CustomError);

			this.snackBar
				.open(message, "OK", { duration })
				.afterDismissed()
				.subscribe(() => (this.canDisplayError = true));

			if (throwError) {
				throw error;
			} else {
				console.error(error);
			}
		}
	}
}
