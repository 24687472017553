import { AsyncPipe, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { enableMapSet } from "immer";
import { TourStepTemplateComponent } from "ngx-ui-tour-md-menu";
import { Observable, of } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { LoadingComponent } from "./components/loading/loading.component";
import { APPLICATION } from "./helpers/consts";
import { ResourceType } from "./models/resource";
import { RouteDealer } from "./models/routes";
import { CURRENT_VERSION, VERSION_KEY } from "./models/version";
import { AuthenticationService } from "./services/authentication.service";
import { Authorization, AuthorizationService } from "./services/authorization.service";
import { DeclarationGroupIsolationService } from "./services/declaration-group-isolation.service";
import { DeclarationGroupStateService } from "./services/declaration-group-state.service";
import { DeclarationIsolationService } from "./services/declaration-isolation.service";
import { DeclarationStateService } from "./services/declaration-state.service";
import { FunctionalDeclarationIsolationService } from "./services/functional-declaration-isolation.service";
import { FunctionalDeclarationStateService } from "./services/functional-declaration-state.service";
import { InitStateService } from "./services/init-state.service";
import { InitialLoadingService } from "./services/initial-loading.service";
import { IsolationStateService } from "./services/isolation-state.service";
import { RepresentativeIsolationService } from "./services/representative-isolation.service";
import { RepresentativeStateService } from "./services/representative-state.service";
import { UserStateService } from "./services/user-state.service";
import { VersionService } from "./services/version.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	standalone: true,
	imports: [NgIf, LoadingComponent, RouterOutlet, TourStepTemplateComponent, AsyncPipe],
})
export class AppComponent {
	title = "decret-tertiaire";
	public loading: boolean = true;

	constructor(
		authentication: AuthenticationService,
		router: Router,
		representativeState: RepresentativeStateService,
		representativeIsolation: RepresentativeIsolationService,
		groupState: DeclarationGroupStateService,
		groupIsolation: DeclarationGroupIsolationService,
		declarationState: DeclarationStateService,
		declarationIsolation: DeclarationIsolationService,
		functionalState: FunctionalDeclarationStateService,
		functionalIsolation: FunctionalDeclarationIsolationService,
		userState: UserStateService,
		isolationState: IsolationStateService,
		authorization: AuthorizationService,
		initService: InitStateService,
		public versionService: VersionService,
	) {
		// add touched class to every input that has been focused
		document.body.addEventListener("focusin", (event) => {
			if (event.target) {
				(event.target as HTMLElement).classList.add("touched");
			}
		});

		// add the theme style
		const themeStyle = document.createElement("link");
		themeStyle.rel = "stylesheet";
		themeStyle.href = environment.baseApiUrl + "/style/" + APPLICATION;
		document.getElementsByTagName("head")[0].appendChild(themeStyle);

		const iconStyle = document.createElement("link");
		iconStyle.rel = "icon";
		iconStyle.href = environment.baseApiUrl + "/favicon/" + APPLICATION;
		document.getElementsByTagName("head")[0].appendChild(iconStyle);

		InitialLoadingService.countSubject.pipe(debounceTime(3000)).subscribe(() => {
			InitialLoadingService.reset();
		});

		enableMapSet();
		const version = localStorage.getItem(VERSION_KEY);
		if (version !== CURRENT_VERSION) {
			authentication.logout();
			localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
		}
		const url = new URL(window.location.href);
		const token = url.searchParams.get("token");
		if (token) {
			const base64decoded = atob(token).split(":");
			if (base64decoded.length === 2) {
				authentication
					.login$(base64decoded[0], base64decoded[1])
					.pipe(
						tap((user) => {
							authentication.logout();
							user.hasNoAccount = true;
							userState.user = user;
							localStorage.setItem("user", JSON.stringify(user));
							localStorage.setItem(VERSION_KEY, CURRENT_VERSION);
						}),
						switchMap(() => authorization.get$()),
						switchMap((authorizations) => {
							const nextResource = url.searchParams.get("next");
							let auth: Authorization | undefined = undefined;
							if (nextResource) {
								auth = authorizations.find((auth) => auth.authorizationId === nextResource);
							}
							if (authorizations.length === 0) {
								return of(void 0);
							}
							const resourceInfo = auth ? auth.resourceInfo : authorizations[0].resourceInfo;

							switch (resourceInfo.type) {
								case ResourceType.FunctionalDeclaration:
									return initService
										.initFunctionalDeclaration$(resourceInfo.id)
										.pipe(tap(() => router.navigate(RouteDealer.qualification(resourceInfo.id))));
								case ResourceType.Declaration:
									return initService
										.initDeclaration$(resourceInfo.id)
										.pipe(tap(() => router.navigate(RouteDealer.myDeclaration(resourceInfo.id))));
								case ResourceType.DeclarationGroup:
									return initService
										.initGroup$(resourceInfo.id)
										.pipe(tap(() => router.navigate(RouteDealer.myDeclaration(resourceInfo.id))));
								case ResourceType.Representative:
									return initService
										.initRepresentative$(resourceInfo.id)
										.pipe(tap(() => router.navigate(RouteDealer.myDeclaration(resourceInfo.id))));
							}
						}),
					)
					.subscribe(
						() => {
							this.loading = false;
						},
						() => {
							router.navigate(RouteDealer.login());
							this.loading = false;
						},
					);
				return;
			}
		}

		const user = localStorage.getItem("user");
		if (user) {
			userState.user = JSON.parse(user);
			const resourceInfo = IsolationStateService.getIsolationFromStorage();
			if (resourceInfo) {
				if (!resourceInfo.id || !resourceInfo.type) {
					this.loading = false;
					router.navigate(RouteDealer.logout());
					return;
				}
				let isSelected: Observable<unknown> | undefined;

				switch (resourceInfo.type) {
					case ResourceType.Representative:
						representativeState.setOwner(representativeIsolation);
						isSelected = representativeState.select$(resourceInfo.id);
						break;
					case ResourceType.DeclarationGroup:
						groupState.setOwner(groupIsolation);
						isSelected = groupState.select$(resourceInfo.id);
						break;
					case ResourceType.Declaration:
						declarationState.setOwner(declarationIsolation);
						isSelected = declarationState.select$(resourceInfo.id);
						break;
					case ResourceType.FunctionalDeclaration:
						functionalState.setOwner(functionalIsolation);
						isSelected = functionalState.select$(resourceInfo.id);
				}

				isolationState.setIsolation(resourceInfo);

				isSelected?.subscribe(
					() => (this.loading = false),
					() => {
						authentication.logout();
						router.navigateByUrl("/logout", { skipLocationChange: true }).then(() => {
							router.navigate(["/"]);
						});
						this.loading = false;
					},
				);

				return;
			}

			this.loading = false;
			return;
		}

		this.loading = false;
	}
}
