import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, finalize, Observable, of, throwError } from "rxjs";
import { InitialLoadingService } from "src/app/services/initial-loading.service";
import { UserStateService } from "src/app/services/user-state.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../services/authentication.service";

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	const router = inject(Router);
	const userState = inject(UserStateService);
	const authentication = inject(AuthenticationService);

	const token = userState.user?.token;
	if (req.url.includes(environment.baseApiUrl) && token !== undefined) {
		req = req.clone({ setHeaders: { Authorization: `Basic ${token}` } });
	}
	InitialLoadingService.increment();
	return next(req).pipe(
		catchError((err: unknown) => {
			if (err instanceof HttpErrorResponse) {
				return handleAuthError(err, req.url, authentication, router);
			}
			throw err;
		}),
		finalize(() => {
			InitialLoadingService.decrement();
		}),
	);
}

function handleAuthError(
	err: HttpErrorResponse,
	url: string,
	authentication: AuthenticationService,
	router: Router,
): Observable<HttpEvent<unknown>> {
	if (err.status === 401 && url.includes(environment.baseApiUrl)) {
		localStorage.setItem("lastUrl", router.url);
		authentication.logout();
		// Have to do this otherwise if we have a 401 on home page it would get stuck
		router.navigateByUrl("/logout", { skipLocationChange: true }).then(() => {
			router.navigate(["/"]);
		});

		// I don't really know what we have to send. I think it's not really important because we tell the router to go elsewhere
		return of({ type: HttpEventType.Sent });
	}
	return throwError(() => err);
}
