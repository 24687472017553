import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UserStateService } from "../services/user-state.service";

export const canLoadNavigation: CanActivateFn = () => {
	const userState = inject(UserStateService);
	const router = inject(Router);
	if (userState.user?.user_id !== undefined) {
		return true;
	}

	return router
		.navigate(["/404"])
		.then(() => Promise.resolve(false))
		.catch(() => Promise.resolve(false));
};
